import { render, staticRenderFns } from "./department.vue?vue&type=template&id=4e14f448&scoped=true&"
import script from "./department.vue?vue&type=script&lang=js&"
export * from "./department.vue?vue&type=script&lang=js&"
import style0 from "./department.vue?vue&type=style&index=0&id=4e14f448&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e14f448",
  null
  
)

export default component.exports